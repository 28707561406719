<template>
  <v-container>
    <step-buttons-therapy
      class="px-0 my-3"
      @click-back="back"
      @click-next="submit"
    />
    <therapy-card title="Passo a passo da terapia">
      <div class="mb-3">
        Se você realizou algum procedimento pré terapia, informe abaixo:
      </div>

      <label-icon icon="mode_comment" label="Comentários" bold />

      <v-textarea
        v-model="therapistComments"
        class="mt-3 text-area-custom"
        label="Informações adicionais (Protocolos, técnicas, procedimentos realizadas antes da terapia, exames complementares, comentários, etc). Este campo não será mostrado ao paciente."
        outlined
        rows="3"
      />
      <v-textarea
        v-model="patientComments"
        label="Comentários gerais ao paciente (mostrado ao paciente)"
        outlined
        rows="3"
      />

      <p class="black--text subtitle-1 font-weight-regular">
        <v-icon>chevron_right</v-icon>
        Esses são os passos da terapia, de acordo com a sequência escolhida:
      </p>
      <v-divider />
      <v-list>
        <div v-for="(property, index) in sequence" :key="property.name">
          <div :class="`mb-3 subtitle-2 ${index !== 0 ? 'mt-5' : ''}`">
            <div class="subtitle-1 text-center my-3">
              <span>{{ property.propertyLabel }}: </span>
              <span class="font-weight-bold">{{ property.name }}</span>
            </div>
            <explanation-icon
              v-if="property.explanation"
              :name="property.name"
              :explanation="property.explanation"
            />
          </div>
          <template v-for="(step, indexStep) in property.steps">
            <step-item
              :key="indexStep"
              :title="step.title"
              :first-word-bold="step.firstWordBold"
              class="elevation-2 mb-2 item-par"
              @click="openDetailDialog(step)"
            />
          </template>
        </div>
      </v-list>
    </therapy-card>
    <step-buttons-therapy
      class="my-3"
      @click-back="back"
      @click-next="submit"
    />
    <auto-scroll @start="scrollToEnd" />
    <dialogs />
    <div ref="lastComponent" />
  </v-container>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import { SEQUENCE } from "@/domains/therapy/steps/mount-sequence/store/sequence/types";
export default {
  components: {
    StepItem: () =>
      import("@/domains/therapy/shared/presentation/components/StepItem"),
    Dialogs: () =>
      import("@/domains/therapy/shared/presentation/dialogs/Dialogs"),
    AutoScroll: () =>
      import(
        "@/domains/therapy/steps/execute/presentation/components/AutoScroll"
      ),
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    cancelScroll: () => {},
  }),
  computed: {
    sequence() {
      const sequence = this.$store.state.sequence.sequence;
      const words = [
        "infiltrado",
        "exsudato",
        "cisto/pólipo",
        "abcesso",
        "displasia",
        "metástase",
        "necrose",
      ];
      let firstWordCancerFound = false;
      let firstWordNeoplasiaFound = false;

      sequence.forEach((property) => {
        property.steps?.forEach((step) => {
          if (
            step.title.toLowerCase().startsWith("câncer") &&
            !firstWordCancerFound
          ) {
            firstWordCancerFound = true;
            step.firstWordBold = true;
          }
          if (
            step.title.toLowerCase().startsWith("neoplasia") &&
            !firstWordNeoplasiaFound
          ) {
            firstWordNeoplasiaFound = true;
            step.firstWordBold = true;
          }
          words.forEach((word) => {
            if (step.title.toLowerCase().includes(word)) {
              step.firstWordBold = true;
            }
          });
        });
      });
      return sequence;
    },
    preTherapyProcedures: {
      get() {
        return this.$store.state.therapy.therapy.preTherapyProcedures;
      },
      set(value) {
        this.$store.commit("therapy/setPreTherapyProcedures", value);
      },
    },
    therapistComments: {
      get() {
        return this.$store.state.diagnosis.diagnose.therapistComments;
      },
      set(value) {
        this.$store.commit("diagnosis/setTherapistComments", value);
      },
    },
    patientComments: {
      get() {
        return this.$store.state.diagnosis.diagnose.patientComments;
      },
      set(value) {
        this.$store.commit("diagnosis/setPatientComments", value);
      },
    },
  },
  created() {},
  methods: {
    openDetailDialog(step) {
      this.$store.dispatch(SEQUENCE.ACTIONS.OPEN_DETAIL_DIALOG, step);
    },
    closeTumoralPhenomenaDialog() {
      this.$store.commit(
        SEQUENCE.MUTATIONS.CHANGE_TUMORAL_PHENOMENA_DIALOG,
        false
      );
    },
    closeImpactionPointsDialog() {
      this.$store.commit(
        SEQUENCE.MUTATIONS.CHANGE_IMPACTION_POINTS_DIALOG,
        false
      );
    },
    submit() {
      console.log("next");
      this.$emit("next");
    },
    back() {
      console.log("back");
      this.$emit("back");
    },
    scrollToEnd() {
      const el = this.$refs.lastComponent;

      if (el) {
        this.cancelScroll = VueScrollTo.scrollTo(
          el,
          this.$store.getters["autoScroll/duration"],
          { easing: "linear" }
        );
      }
    },
  },
};
</script>

<style>
.text-area-custom.v-textarea.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined
  > div
  > div.v-input__slot
  > div
  > label {
  white-space: pre-line !important;
  height: auto;
}
</style>
